

























































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import FcDate from '@/components/FcDate.vue';
import { BasicMusics, useMusic } from '@/composition/music';
import { useNotification } from '@/composition/notification';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'Music',
  components: {
    FcDate,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    // 編集する楽曲の楽曲Id
    musicId: { type: String, require: false },
  },
  setup(props, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('musics'));
    const music = useMusic();
    const notification = useNotification();

    const isLoading = ref(false); // ロード中フラグ
    const isSaving = ref(false); // DB更新中フラグ

    /**
     * 楽曲登録・編集処理
     *
     * @param musicProps - 登録・編集する楽曲情報
     * @param musicId - 編集する楽曲Id
     */
    const saveMusic = async (musicProps: BasicMusics, musicId?: string) => {
      isSaving.value = true;
      try {
        await music.saveMusic(musicProps, musicId);
        musicId ? notification.notify('楽曲を編集しました') : notification.notify('楽曲を登録しました');
        context.root.$router.push('/musics');
      } catch (e) {
        notification.error(e);
      } finally {
        isSaving.value = false;
      }
    };

    /**
     * 初期表示処理
     */
    const init = async () => {
      if (props.musicId) {
        isLoading.value = true;
        await music.getMusic(props.musicId);
        isLoading.value = false;
      }
    };

    init();

    return {
      pageTitle: props.musicId ? '楽曲編集' : '楽曲登録',
      myRoleSettings,
      music,
      isLoading,
      isSaving,
      saveMusic,
    };
  },
});
